import React, {useState} from "react";
import { Row, Col,Form,Tree, Alert, Divider,Card, Input,Button,Result,List,Tag } from "antd";
import request from '../../../../../shared/helpers/request';
import ResultadoBusqueda from './shared/ResultadoBusqueda';
import $ from 'jquery';
const BusquedaSimpleTab = (props) => {
  const [form] = Form.useForm();
  const [primeraBusqueda, setPrimeraBusqueda] =  useState(false);
  const [datosBusqueda, setDatosBusqueda] = useState([]);
  const [treeData, setTreeData] = useState([]);
  const [buscando,setBuscando] = useState(false);
  const [searchText,setSearchText] = useState('');
  const inputref = React.createRef();

  const filtroTexto = (text) => {
      return text.replace(/[/-]/g," ")
  }


  const onFinish = (e) => {
    e.preventDefault();
    setBuscando(true);
    let texto = filtroTexto(e.target.texto.value)
    const apiUrl = '/documentos/busqueda-simple?txtBusqueda='+texto;
    request.get(apiUrl).then(response=>{
      setBuscando(false);
      setDatosBusqueda(response.data);
      setPrimeraBusqueda(true);
    });
    
  }; 

  const onSelect = (selectedKeys, info) => {
    console.log('selected', selectedKeys, info);
    $(inputref.current).find('input').each(function(index,elem){
      console.log($(elem).val());
    });
    console.log(props);
    if(selectedKeys.length >0) {
    const apiUrl = '/documentos/por-categoria?categoriaId='+selectedKeys;
    setBuscando(true);
    request.get(apiUrl).then(response=>{
      setBuscando(false);
      setPrimeraBusqueda(true);
      setDatosBusqueda(response.data);
    });
  }
  };

  if( treeData.length === 0 ){
    
    const apiUrl = '/categorias/tree';
    request.get(apiUrl).then(response=>{
      setTreeData(response.data);
    });
  }


  return (
    <div  style={{ height: "100%" }}>
      <form id="busqueda" onSubmit={onFinish}>
      <Card style={{ backgroundColor:'#f2f2f0' }}  >
        <Row type="flex" justify='center' >
        <Col style={{fontSize:'130%',textAlign:'center',paddingTop:'0.3rem',fontWeight:'500'}} md={4} lg={7} >
          Ingrese las palabras de la búsqueda: 
        </Col>
        <Col md={11} lg={11} >  
        <div ref={inputref} >
          <Form.Item  name ='txtBusqueda'>
            
            <Input
              onChange={(e) => setSearchText(e.target.value)}
              style={{ fontSize:'18px' }}
              placeholder="texto de la resolucion, año, numero " 
              name="texto"
            />
            
          </Form.Item>
          </div>
        </Col>
        <Col md={2}>     
          <Button size={'large'} disabled={ buscando || !searchText }  type="primary" htmlType="submit">Buscar</Button>
        </Col>
        <Col md={4}>     
          <Button size={'large'} disabled={ buscando } type="primary" style={{ }} onClick={()=>{props.onChangeTab('avanzada')}}>Busqueda Avanzada</Button>
        </Col>

        

        </Row>
       
       
        <Row style={{ marginTop:'2rem' }} type="flex"
         justify='center'>
            
        <Col md={24} lg={7}  >
       
            <p style={{ fontSize:'130%',fontWeight:'500' }}>Seleccione Categoría</p>
            <Tree
              showLine = { true }
              showIcon = { true }
              defaultExpandedKeys = { ['01'] }
              onSelect = { onSelect }
              treeData = {  treeData }
              style={{ backgroundColor:'#f2f2f0',fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'",fontSize:'112%' }}
            />
            <Divider type="vertical" />
           
          </Col>
          <Col   lg={17} md={24}>
            
            <ResultadoBusqueda buscando={ buscando } primeraBusqueda= {primeraBusqueda} resultadosBusqueda={ datosBusqueda } userData ={ props.userData }/>
          </Col>
         
        </Row>
        </Card>
        </form>
    </div>
  );
};

export default BusquedaSimpleTab;