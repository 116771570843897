
import React from "react";
import { Switch, useRouteMatch, withRouter } from "react-router-dom";
import { Route } from "react-router-dom";
import UsersForm from './components/form/UsersForm';
import UsersList from "./components/list/UsersList";
import UsersView from "./components/view/UsersView";
 
//function UsersPage() {
const UsersPage = () => {
  let { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={`${path}/`} component={ UsersList } />
      <Route path={`${path}/new`} component={ UsersForm } />
      <Route path={`${path}/edit/:id`} component={ UsersForm } />
      {/*<Route path={`${path}/view/:id`} component={ UsersView } />*/}
    </Switch>
  );
}

export default UsersPage;