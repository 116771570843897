import React from "react";
import { Breadcrumb } from "antd";
import AuthenticationHelper from "../../../../../shared/helpers/authenticationHelper";

const AppBreadcrumb = () => {
  if (AuthenticationHelper.isUserAuthenticated()){
    const user = AuthenticationHelper.getUser();

    return (
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Usuario</Breadcrumb.Item>
        <Breadcrumb.Item>{ user }</Breadcrumb.Item>
      </Breadcrumb>
    );
  }else{
    return null;
  }
}

export default AppBreadcrumb;
