import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { PageHeader, Table, Tag, Button, Descriptions, Tabs, Card, Modal, List } from "antd";
import { EditOutlined } from '@ant-design/icons';
import PermisosModal from "./PermisosModal";

const DescItem = Descriptions.Item;
const TabPane = Tabs.TabPane;
const ListItem = List.Item;

const UsersView = props => {
  const [showPermisosModal, setShowPermisosModal] = useState(false);

  return (
    props.user 
      ? <div>
        <Card>
          <Descriptions size="small" column={ 1 }>
            <DescItem label="Nombre"><b>{ props.user.name }</b></DescItem>
            <DescItem label="Edad"><b>{ props.user.age }</b></DescItem>
            <DescItem label="Dirección"><b>{ props.user.address }</b></DescItem>
          </Descriptions>
        </Card>
        <Tabs defaultActiveKey="1" style={ { marginTop: 10 } }>
          <TabPane tab="Permisos" key="1">
            <Button icon={ <EditOutlined /> } onClick={ () => setShowPermisosModal(true) }>Editar permisos</Button>
            <List style={ { marginTop: 10 } }
              size="small"
              footer={ <div>{ props.user.permisos?.length } permisos</div> }
              bordered
              dataSource={ props.user ? props.user.permisos : [] }
              renderItem={item => {
                let color = item.includes('Ver') ? 'geekblue' : item.includes('Eliminar') ? 'volcano' : 'green';
                return <ListItem><Tag color={ color } key={ item }>{ item.toUpperCase() }</Tag></ListItem>;
              } }
            />
          </TabPane>
          <TabPane tab="Tab 2" key="2">
            Content of Tab Pane 2
          </TabPane>
          <TabPane tab="Tab 3" key="3">
            Content of Tab Pane 3
          </TabPane>
        </Tabs>

        <PermisosModal
          visible={ showPermisosModal }
          setVisible={ setShowPermisosModal }
          user={ props.user }
        />
      </div>
      : ''
  );
}

export default UsersView;

