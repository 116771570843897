import React, { useEffect, useState } from "react";
import request from '../../../../../../shared/helpers/request';
import { Tag, List, Button, Alert } from "antd";
import { pdfjs } from 'react-pdf';
import { EyeFilled, CloudDownloadOutlined } from '@ant-design/icons';

import { Document, Page } from 'react-pdf'
import {
  Link, useHistory
} from "react-router-dom";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;


const tipos = {
  'ORD': 'Ordenanza',
  'LEY': 'Ley',
  'DES': 'Despacho',
  'DTO': 'Decreto',
  'RES': 'Resolución',
  'REC': 'Recomendación',
  'DEC': 'Declaración',
  'CIR': 'Circular',
  'ACT': 'Acta',
  'DIS': 'Disposicion',
  'COM': 'Comunicación',
  'NTA': 'Nota'
}

const facultad = {
  'FRLP': 'Facultad Regional La Plata',
  'FRSF': 'Facultad Regional Santa Fe',
  'CRUN': 'Consejo de Rectores de Univer. Nac.',
  'MCYE': 'Ministerio de Cultura y Educacion',
  'UACO': 'Unidad Academica Confluencia',
  'UAVT': 'Unidad Academica Venado Tuerto',
  'CSUMA': 'Interventor Alberto Montes',
  'SESUP': 'Sec. De Educación Superior  (MCYE)',
  'SECYT': 'Sec. De Ciencia Y Tecnología. (PEN)',
  'PEN': 'Poder Ejecutivo Nacional',
  'PLN': 'Poder Legislativo Nacional',
  'UBA': 'Universidad De Buenos Aires',
  'REC': 'Rectorado',
  'SFP': 'Secretaría de la Funcion Pública',
  'CNAOP': 'Com Nac Aprendizaje y Orientacion Prof',
  'AU': 'Asamblea Universitaria',
  'DGA': 'Dirección General Administracion',
  'CSUWR': 'Interventor Rolando Weidembach',
  'SAD': 'Secretaria Administrativa',
  'CSUPT': 'Interventor Tomás Persichini',
  'CSUCC': 'Interventor Cecilio Conditi',
  'CSUSC': 'Interventor Carmelo Soriano',
  'CSUCE': 'Delegado Militar Com. Echenique',
  'CSUCO': 'Interventor Omar Conca',
  'CSUBC': 'Interventor Carlos Burandarena',
  'CSUGR': 'Interventor Roberto Guillán',
  'CSUCI': 'Interventor Iván Chambouleyron',
  'CSUCOE': 'Comisión De Enseñanza Del CSU',
  'CSUCOA': 'Comisión De Adm. Y Presup. Del CSU',
  'CSUCOP': 'Comisión De Planeamiento Del CSU',
  'CSUCOI': 'Comisión De Int.y Reglamento Del CSU',
  'FRGP': 'Facultad Regional General Pacheco',
  'FRVM': 'Facultad Regional Villa María',
  'FRVT': 'Facultad Regional Venado Tuerto',
  'UATL': 'Unidad Académica Trenque Launquen',
  'FRBA': 'Facultad Regional Buenos Aires',
  'FRBB': 'Facultad Regional Bahía Blanca',
  'FRRA': 'Facultad Regional Rafaela',
  'FRA': 'Facultad Regional Avellaneda',
  'FRRE': 'Facultad Regional Resistencia',
  'FRD': 'Facultad Regional Delta',
  'FRRG': 'Facultad Regional Río Grande',
  'UALR': 'Unidad Académica La Rioja',
  'FRH': 'Facultad Regional Haedo',
  'FRCU': 'Facultad Regional Concepción del Uruguay',
  'FRM': 'Facultad Regional Mendoza',
  'FRRO': 'Facultad Regional Rosario',
  'FRP': 'Facultad Regional Paraná',
  'FRT': 'Facultad Regional Tucumán',
  'FRSN': 'Facultad Regional San Nicolás',
  'FRSR': 'Facultad Regional San Rafael',
  'UAC': 'Unidad Académica Concordia',
  'UARG': 'Unidad Académica Río Gallegos',
  'UARQTA': 'Unidad Académica Reconquista',
  'FRSFCO': 'Facultad Regional San Francisco',
  'CSU': 'Consejo Superior',
  'UACH': 'Regional Académica Chubut',
  'FRC': 'Unidad Académica Concordia',
  'VRAC': 'Vicerector A Cargo',
  'DASUTE': 'DASUTeN'
}


function DocumentRelationsViewer(props) {
  const [documentosData, setDocumentosData] = useState({});
  const [documentId, setDocumentId] = useState('');
  const [documentBase, setDocumentBase] = useState('');
  const [numPages, setNumPages] = useState(null);
  const [searching, setSearching] = useState(true);
  const history = useHistory()

  useEffect(() => {
    getDocumentInfo();
    if (documentId)
      getDocument();
  }, [documentId]);



  const { documentoId } = props;
  if (documentoId !== documentId) setDocumentId(documentoId);

  const getDocumentInfo = () => {
    if (documentId === undefined) return;
    const fileUri = `${window.location.protocol}//${window.location.host}/api/documentos/show-documento-completo?documentoId=${documentoId}`
    try {
      setSearching(true);
      request
        .get(fileUri)
        .then((response) => {
          setDocumentosData(response.data);
          setSearching(false);
        })
        .catch((error) => {
          console.error(error);
        });
    } catch (error) {
      return { error };
    }
  }
  const renderCabecera = () => {
    if (documentosData.documento && documentosData.documento.length > 0) {
      console.log(documentosData);
      return <div>
        <h5>{tipos[documentosData.documento[0].tipo] + ' ' + facultad[documentosData.documento[0].facultad]} <Tag color="#87d068">{documentosData.documento[0].numero}</Tag> {documentosData.documento[0].anio ? <Tag color="#2db7f5">{documentosData.documento[0].anio}</Tag> : <span />}</h5>
        <p>{documentosData.documento[0].tematica}</p>

      </div>
    } else {
      return null;
    }
  }
  const renderRelacionActiva = (relacion) => {
    switch (relacion) {
      case 'IMP':
        return <Tag color="volcano">Reimplanta a</Tag>; break;
      case 'MOD':
        return <Tag color="volcano">Modifica a</Tag>; break;
      case 'AMP':
        return <Tag color="volcano">Amplía a</Tag>; break;
      case 'REF':
        return <Tag color="volcano">Referencia a</Tag>; break;
      case 'DER':
        return <Tag color="volcano">Deroga a</Tag>; break;
      case 'DEP':
        return <Tag color="volcano">Deroga Parcialmente a</Tag>; break;
      case 'DEP':
        return <Tag color="volcano">Suspende a</Tag>; break;
      default:
        break;
    }
  }
  const renderRelacionPasiva = (relacion) => {
    switch (relacion) {
      case 'IMP':
        return <Tag color="volcano">Es reimplantada por</Tag>; break;
      case 'MOD':
        return <Tag color="volcano">Es Modificado por</Tag>; break;
      case 'AMP':
        return <Tag color="volcano">Es Ampliado por</Tag>; break;
      case 'REF':
        return <Tag color="volcano">Es Referenciado por</Tag>; break;
      case 'DER':
        return <Tag color="volcano">Es Derogado por</Tag>; break;
      case 'DEP':
        return <Tag color="volcano">Es Derogado Parcialmente por</Tag>; break;
      case 'DEP':
        return <Tag color="volcano">Es Suspendido por</Tag>; break;
      default:
        break;
    }
  }
  const renderCategorias = () => {
    if (documentosData.relaciones && documentosData.relaciones.categorias && documentosData.relaciones.categorias.length > 0) {
      return (
        <div style={{ margin: 10 }}>
          <List
            size="small"
            header={<div style={{ fontWeight: 'bold' }}>Categorias</div>}
            bordered
            dataSource={documentosData.relaciones.categorias}
            renderItem={item => {
              let text = '';
              item.forEach((elem, indice) => {
                if (indice == 0)
                  text += elem.nombre;
                else
                  text += '->' + elem.nombre;

              })
              return <List.Item>{text}</List.Item>

            }}
          />
        </div>
      );
    } else {
      return null//return <Divider orientation="left" plain> Este documento <b>no esta listado</b> en ninguna categoria</Divider>
    }
  }
  const doPush = () => {
    history.push('/home?documentoId=CSU**RES**1999**851');
  }
  const renderRelacionesDirectas = () => {
    //relaciones derecha
    if (documentosData.relaciones && documentosData.relaciones.directo && documentosData.relaciones.directo.length > 0) {
      return (
        <div style={{ margin: 10 }}>
          <List
            size="small"
            header={<div> Relaciones Activas </div>}
            bordered
            dataSource={documentosData.relaciones.directo}
            renderItem={
              item => <List.Item>{renderRelacionActiva(item.tipo_relacion)} <Link to={`/home?documentoId=${item.dependencia}**${item.tipo_doc}**${item.anio}**${item.numero}`}>{item.label}</Link> </List.Item>
            }
          />
        </div>
      );
    } else {
      return null//return <Divider orientation="left" plain> Este documento <b>no referencia</b> a ningun otro documento.</Divider>
    }
  }
  const renderRelacionesIndirectas = () => {
    //relaciones reverso
    if (documentosData.relaciones && documentosData.relaciones.reverso && documentosData.relaciones.reverso.length > 0) {
      return (
        <div style={{ margin: 10 }}>
          <List
            size="small"
            header={<div>Relaciones Pasivas</div>}
            bordered
            dataSource={documentosData.relaciones.reverso}
            renderItem={
              item => {
                return <List.Item>{renderRelacionPasiva(item.tipo_relacion)} <Link to={`/home?documentoId=${item.dependencia}**${item.tipo_doc}**${item.anio}**${item.numero}`}>{item.label}</Link></List.Item>
              }
            }
          />
        </div>
      );
    } else {
      return null//return <Divider orientation="left" plain> Este documento <b>no es referenciado</b> por ningun otro documento.</Divider>
    }
  }

  const armarPages = () => {
    let arrayReturn = [];
    for (let i = 0; i < numPages; i++) {

      arrayReturn.push(<Page pageNumber={i + 1} />)
      arrayReturn.push(<br />)

    }
    return arrayReturn

  }

  const getDocument = () => {
    const fileUri = `${window.location.protocol}//${window.location.host}/api/documentos/get-pdf-base64?documentoId=${documentId}`
    try {
      request
        .get(fileUri, {
          responseType: "arraybuffer",
        })
        .then((response) => {
          setDocumentBase(response.data.data);
        })
        .catch((error) => {
          console.error(error);
        });
    } catch (error) {
      return { error };
    }
  }

  const showDocument = () => {

    const { facultad, tipo, anio, numero } = documentosData.documento[0];
    const id = `${facultad}**${tipo}**${anio}**${numero}`;
    const fileUri = `${window.location.protocol}//${window.location.host}/api/documentos/get-pdf-base64?documentoId=${id}`
    try {
      request
        .get(fileUri, {
          responseType: "arraybuffer",
        })
        .then((response) => {
          var objbuilder = '';
          objbuilder += ('<object width="100%" height="100%" data="data:application/pdf;base64,');
          objbuilder += (response.data.data);
          objbuilder += ('" type="application/pdf" class="internal">');
          objbuilder += ('<embed src="data:application/pdf;base64,');
          objbuilder += (response.data.data);
          objbuilder += ('" type="application/pdf"  />');
          objbuilder += ('</object>');
          var win = window.open("#", "_blank");
          var title = "Imprimir Documento";
          win.document.write('<html><title>' + title + '</title><body style="margin-top: 0px; margin-left: 0px; margin-right: 0px; margin-bottom: 0px;">');
          win.document.write(objbuilder);
          win.document.write('</body></html>');
        })
        .catch((error) => {
          console.error(error);
        });
    } catch (error) {
      return { error };
    }
  }

  const downloadDocument = () => {
    const { facultad, tipo, anio, numero } = documentosData.documento[0];
    const id = `${facultad}**${tipo}**${anio}**${numero}`;
    const fileUri = `${window.location.protocol}//${window.location.host}/api/documentos/get-pdf-base64?documentoId=${id}`
    try {
      request
        .get(fileUri, {
          responseType: "arraybuffer",
        })
        .then((response) => {

          const linkSource = `data:application/pdf;base64,${response.data.data}`;
          const downloadLink = document.createElement("a");
          const fileName = id.replace('**', '_') + ".pdf";
          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
        })
        .catch((error) => {
          console.error(error);
        });
    } catch (error) {
      return { error };
    }

  }




  return (
    <div style={{ margin: '30px' }}>
      <div>
        {renderCabecera()}
        {renderCategorias()}
        {renderRelacionesDirectas()}
        {renderRelacionesIndirectas()}
        <Button style={{ marginRight: '7px' }} size={'small'} type="primary" onClick={() => showDocument()} icon={<EyeFilled />}>Ver Documento</Button>
        <Button style={{ marginRight: '7px' }} size={'small'} type="primary" onClick={() => downloadDocument()} icon={<CloudDownloadOutlined />}>Descargar</Button>
        <div style={{marginBottom:'20px', marginTop:'20px'}}>
          <Alert type="warning" showIcon message="Para poder ver y comprobar la firma digital en el Documento es necesario descargar el mismo" />
        </div>
        <div style={{ marginTop: '35px', height: '700px', overflowY: 'scroll' }}>
          <Document
            onLoadSuccess={({ numPages }) => setNumPages(numPages)}
            file={`data:application/pdf;base64,${documentBase}`}>


            {armarPages()}
          </Document>
        </div>
      </div>
    </div>
  );
}

export default DocumentRelationsViewer;
