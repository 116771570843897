import React, { useState } from "react";
import { useHistory } from "react-router";
import { Form, Input, Button, message } from "antd";
import { GLOBAL_FORM_LAYOUT } from "../../../../shared/config/GlobalConfig";
import LoginApi from "../../api/LoginApi";

const FormItem = Form.Item;

const LoginForm = () => {
  const history = useHistory();
  const [working, setWorking] = useState(false);

  const onFinish = (values) => {
    setWorking(true);
    LoginApi.Login(values.email, values.password)
      .then((response) => {
        setWorking(false);
        history.push("/modules");
      })
      .catch((error) => {
        setWorking(false);
        console.error("fallo");
        message.error("Ocurrio un error en el Servidor.");
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Form
      { ...GLOBAL_FORM_LAYOUT }
      name="basic"
      initialValues={ {remember: true} }
      labelAlign="left"
      onFinish={ onFinish }
      onFinishFailed={ onFinishFailed }
    >
      <FormItem label="Email" name="email"
        rules={ [{ required: true, message: "Ingresa tu usuario por favor."}] }
      >
        <Input />
      </FormItem>

      <FormItem label="Password" name="password"
        rules={ [{ required: true, message: "El password es requerido." }] }
      >
        <Input.Password />
      </FormItem>

      <FormItem { ...{ wrapperCol: { span: 24 } } }>
        <Button block type="primary" htmlType="submit" loading={ working }>Ingresar</Button>
      </FormItem>
    </Form>
  );
};

export default LoginForm;
