import React from "react";
import { Layout } from "antd";
import { Route, Switch, withRouter } from "react-router-dom";

import AuthorizationHelper from '../../../../shared/helpers/authorizationHelper';
import AppBreadcrumb from "./breadcrumb/AppBreadcrumb";
import SystemVarsPage from "../../../administration/systemVars/SystemVarsPage";
import UsersPage from "../../../administration/users/UsersPage";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import './AppContent.scss';

const { Content } = Layout;

const AppContent = ({location}) => {console.debug(location);
  return (
    <Content>
      <div style={ { paddingLeft: 16, paddingRight: 16, height: '100%' } }>
        
        <AppBreadcrumb />

        <TransitionGroup className="transition-group">
          <CSSTransition
            key={ location.key }
            classNames="slide"
            timeout={ 1000 }
          >
            <section className="route-section">
              <Switch location={ location }>
                <Route path="/administration" component={ AuthorizationHelper.requireAuth(UsersPage) }/>
                <Route path="/administration/system-vars" component={ AuthorizationHelper.requireAuth(SystemVarsPage) }/>
              </Switch>
            </section>
          </CSSTransition>
        </TransitionGroup>

      </div>
    </Content>
  );
}

export default withRouter(AppContent);