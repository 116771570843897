import React, { useEffect, useState } from 'react';
import { Layout,Button, Row, Col, Popover, Tooltip, Divider, message, Badge, Space } from 'antd';
import { useHistory } from "react-router";

import AuthenticationHelper from '../../../../shared/helpers/authenticationHelper';
import { UserOutlined, MailTwoTone, BellTwoTone } from '@ant-design/icons';
import './AppHeader.scss';
import Avatar from 'antd/lib/avatar/avatar';

const { Header } = Layout;

const AppHeader = () => {
  let history = useHistory();
  const user = AuthenticationHelper.getUser();

  const logout = () => {
    AuthenticationHelper.logout(() => history.push("/login"));
  }

  return (
    <Header className="app-layout-background" style={ { padding: '0 15px' } }>
      <Row>
        
        <Col xs={ 12 } sm={ 12 } md={ 12 } lg={ 12 }>
          <h2 style={ { fontWeight: 100, margin: 0 } }>CSU Buscador</h2>
        </Col>

        <Col xs={ 12 } sm={ 12 } md={ 12 } lg={ 12 } style={ { textAlign: 'right' } }>
          <Space size="large" style={ { marginTop: 8 } }>
            {/*<Badge count={ 1 }>
              <Button type="text" shape="circle" className="header-icon"
                icon={ <MailTwoTone style={ { fontSize: 22 } } /> }
              />
            </Badge>
            <Badge count={ 5 }>
              <Button type="text" shape="circle" className="header-icon"
                icon={ <BellTwoTone style={ { fontSize: 22 } } /> }
              />
            </Badge>*/}
            <Popover placement="bottomRight" title="Usuario" 
              content={ 
                <div style={ { width: 150 } }>
                  <Button style={ { textAlign: 'left', width: '100%' } } type="text" onClick={ () => { message.info('Opción 1') } }>Opción 1</Button>
                  <Button style={ { textAlign: 'left', width: '100%' } } type="text" onClick={ () => { message.info('Opción 2') } }>Opción 2</Button>
                  <Button style={ { textAlign: 'left', width: '100%' } } type="text" onClick={ () => { message.info('Opción 3') } }>Opción 3</Button>
                  <Divider style={ { marginTop: 8, marginBottom: 8 } } />
                  <Button style={ { textAlign: 'left', width: '100%' } } type="text" onClick={ logout }>Salir</Button>
                </div>
              } 
              trigger="click">
              <Tooltip title={ user } placement="left">
                {/*<Button type="primary" shape="circle" icon={ <UserOutlined /> } style={ { marginLeft: 16 } } />*/}
                <Avatar size="large" style={{ backgroundColor: '#009AEA', marginBottom: 16, cursor: 'pointer' }} icon={<UserOutlined />} />
              </Tooltip>
            </Popover>
          </Space>
        </Col>

      </Row>
    </Header>
  );
}

export default AppHeader;
