import React, {useEffect, useState} from "react";
import { Tabs,Button,Tooltip, message,PageHeader } from "antd";
import BusquedaSimpleTab from "./components/BusquedaSimpleTab";
import BusquedaPorCategoriasTab from "./components/BusquedaPorCategoriasTab";
import BusquedaAvanzadaTab from "./components/BusquedaAvanzadaTab";
import UploadDocumentosForm from './components/UploadDocumentosForm';
import LoginForm from './components/LoginForm';
import request from '../../../../shared/helpers/request';
import DocumentRelationsViewer from './components/shared/DocumentRelationsViewer';
import moment from 'moment';
import {
  Link,useHistory,useLocation
} from "react-router-dom";
import './BusquedaTab.scss';
import {
  CloudUploadOutlined,
} from "@ant-design/icons";
import { OmitProps } from "antd/lib/transfer/ListBody";

const { TabPane } = Tabs;

const BusquedaTab = (props) => {
  const url = new URLSearchParams(useLocation().search).get("documentoId");
  const [openDrawer, setDrawerStatus] = useState(false);
  const [openLoginForm, setLoginForm] = useState(false);
  const [userData, setUserData] = useState(null);
  const [currentTab, setCurrentTab] = useState('simple');
  const [documentIdToBeShowed,setDocumentIdToBeShowed] = useState(url);
  const history = useHistory();

  const uploadDocumento = (data) =>{
    const formData = new FormData();
    formData.append('anio',data.anio);
    formData.append('numero',data.numero);
    formData.append('facultad',data.facultad);
    formData.append('tipo',data.tipo);
    formData.append('fecha',moment(data.fecha).format('YYYY-MM-DD'));
    formData.append('archivo', data.archivo, data.archivo.name );
    formData.append('categorias',data.categorias.join(','));
    formData.append('tematica',data.tematica);
    formData.append('user',userData.user);
    formData.append('password',userData.password);
    request.postMultipart('/documentos/crear',formData,()=>message.success(`File subido`))
    .then(()=>{
      setDrawerStatus(false);
      message.success('Documento Subido Correctamente');
    });
  }

  const loginInBackend = (data) =>{
    request.get(`/auth/validar-user?email=${data.user}&password=${data.password}`)
    .then( resp => {
      if(resp.data.logueado === true){
        message.success('Usuario Logueado!');
        setLoginForm(false)
        setDrawerStatus(true);
        setUserData(data);
      }else{
        message.error('Usuario Invalido!');
      }
    })
    .catch(()=>{
      message.error('Error en el Servidor!');
    });
  }

  const checkLogin = (evt) =>{
    if(userData !== null){
      setDrawerStatus(true);
    }else{
      setLoginForm(true);
    }
  }

  const botonUploadDocumento = (
    <div style={{ marginRight: 10 }}>
      <Tooltip placement="left" color={'orange'} title="Ingresar Documentos [Admin]">
        <Button
          type="default"
          shape="circle"
          icon={<CloudUploadOutlined />}
          size={'small'}
          onClick={checkLogin}
        />
      </Tooltip>
    </div>
  );

  useEffect(() => {
    if(url !==  documentIdToBeShowed)
    setDocumentIdToBeShowed(url);
  } );

  const cambiarTab = (tabKey) => { 
    history.push('/home');
    setCurrentTab(tabKey);}

    let style1;
    let style2;
    if(documentIdToBeShowed){
      style1 = { display:'none' }
      style2 = { display:'block' }
    }else{
      style1 = { display:'block' }
      style2 = { display:'none' }

    }


  return (
    <>
      <div Class="container" style={{ height: "100%" }}>
        
        <div className="site-card-wrapper">
          <div style={style1}>
          <Tabs 
            tabPosition={'bottom'} 
            tabBarExtraContent={botonUploadDocumento} 
            centered
            size={ 'small' }
            activeKey={currentTab}
            onTabClick={ cambiarTab  }
          >
            <TabPane tab="Simple" key="simple" >
              <BusquedaSimpleTab onChangeTab={ cambiarTab } userData ={ userData }/>
            </TabPane>
            { /*
            <TabPane tab="Por Categorias" key="categorias">
              <BusquedaPorCategoriasTab onChangeTab={ cambiarTab } userData ={ userData }/>
            </TabPane>
            */ }
            <TabPane tab="Avanzada" key="avanzada">
              <BusquedaAvanzadaTab onChangeTab={ cambiarTab } userData ={ userData }/>
            </TabPane>
          </Tabs>
          </div>
          <div style={style2}>
          <PageHeader
            className="site-page-header"
            onBack={() => history.push('/home')}
            title="Detalles Documento"
            subTitle=""
          />
          <DocumentRelationsViewer documentoId = {documentIdToBeShowed}/>
          </div>
        </div>
        
      
      </div>
          
      <UploadDocumentosForm visible={openDrawer} onClose = {()=>setDrawerStatus(false)} onSubmit = { uploadDocumento }/>
      <LoginForm 
        visible={openLoginForm} 
        onClose = {()=>setLoginForm(false)} 
        onSubmit = { loginInBackend }
      />
    </>
  );
};

export default BusquedaTab;
