import React from "react";
import { useRouteMatch } from "react-router-dom";
import { Switch, Route } from "react-router-dom";
import LoginPage from "./components/loginPage/LoginPage";
import ForgotPasswordForm from "./components/forgotPasswordForm/ForgotPasswordForm";

const LoginContainer = () => {
  let { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={ `${path}/` } component={ LoginPage } />
      <Route path={ `${path}/forgot-password` } component={ ForgotPasswordForm } />
    </Switch>
  );
}

export default LoginContainer;
