import React from "react";
import LoginForm from "./LoginForm";
import logo from '../../assets/logo-cpa.png';
import background from '../../assets/bg-login.jpg';
import './LoginPage.scss';

const LoginPage = () => {
  return (
    <div style={ { height:'100vh' } }>
      <div className="login-viewport" style={ { backgroundImage: `url(${background})`} }>
      <div className="login-viewport-cover" />
      </div>

      <div className="login-main">
        <div className="logoWrapper">
          <span><img src={ logo } alt="logo" className="logoImg" /></span>
          <span>Bienvenido a CPA</span>
        </div>
        
        <div>
          <LoginForm />
        </div>
      </div>

    </div>
  )
}

export default LoginPage;