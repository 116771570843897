import jwtDecode from 'jwt-decode';

/* eslint class-methods-use-this: 0 */

class AuthenticationHelper {
  static isUserAuthenticated() {
    return sessionStorage.jwtToken ? true : false;
  }

  static logout = (callback = () => {}) => {
    AuthenticationHelper.removeJwtToken();
    sessionStorage.removeItem("userPermissions");
    callback();
  };

  static isJwtTokenStored() {
    return sessionStorage.jwtToken ? true : false;
  }

  static getJwtToken() {
    return AuthenticationHelper.isJwtTokenStored() ? sessionStorage.jwtToken : null;
  }

  static storeJwtToken(jwtToken) {
    if (jwtToken.indexOf("Bearer ") >= 0) {
      jwtToken = jwtToken.replace("Bearer ", "");
    }
    sessionStorage.setItem("jwtToken", jwtToken);
    return null;
  }

  static removeJwtToken() {
    sessionStorage.removeItem("jwtToken");
    return null;
  }

  static hasJwtPayloadKey(key) {
    if (AuthenticationHelper.isJwtTokenStored()) {
      const decodedToken = jwtDecode(sessionStorage.jwtToken);
      if (!key) {
        return null;
      }
      return decodedToken[key] !== undefined;
    }
    return null;
  }

  static getValueFromJwtPayload(key) {
    if (AuthenticationHelper.isJwtTokenStored) {
      const decodedToken = jwtDecode(sessionStorage.jwtToken);
      if (!key) {
        return null;
      }
      return decodedToken[key];
    }
    return null;
  }

  static getDecodedToken() {
    return AuthenticationHelper.isJwtTokenStored ? jwtDecode(sessionStorage.jwtToken) : null;
  }

  static storeUser(user) {
    sessionStorage.setItem("user", user);
    return null;
  }

  static getUser() {
    return sessionStorage.user ? sessionStorage.user : '';
  }

  static removeUser() {
    sessionStorage.removeItem("user");
    return null;
  }
}
export default AuthenticationHelper;
