import axios from 'axios';
import AuthenticationHelper from './authenticationHelper';
import { notification,message } from 'antd';

axios.defaults.baseURL = `${window.location.protocol}//${window.location.host}/api/`;

axios.interceptors.request.use(config => {
  config.headers = AuthenticationHelper.isJwtTokenStored() ? { ...config.headers, Authorization: `Bearer ${AuthenticationHelper.getJwtToken()}` } : config.headers;
  return config;
});

axios.interceptors.response.use(
  response => {
    if(response.data.ValidationErrors){
      for (const validationType in response.data.ValidationErrors) {
        const validationMessage = response.data.ValidationErrors[validationType]; 
        message.error("[Validacion Servidor] " + validationMessage);
      }
    }
    return response;
  },
  error => {
    if(error.response){
      if (error.response.status === 401) {
        return Promise.reject(error);
      }
      if (error.response.status === 404) {
        message.error(
          "[Endpoint No encontrado] " +
          error.response.config.baseURL +
          error.response.config.url
        );
      }

      if (error.response.status === 500) {
        let message;
        try {
          message = error.response.data.error ? error.response.data.error : 'Ocurrio un error...';
        } catch (e) {
          message = 'Ocurrio un error...';
        }
        notification['error']({
          message: 'Error',
          description: message,
        });
        return Promise.reject(error);
      }
    }
    return Promise.reject(error);
  }
);

export default class Request {
  static get(path, callback) {
    return axios.get(path, { callback });
  }

  static post(path, data = {}, callback) {
    return axios.post(path, data, { callback });
  }

  static postMultipart(path, data = {}, callback) {
    return axios.post(path, data, { callback, headers: {'Content-Type': `multipart/form-data; boundary=${data._boundary}` } });
  }

  static put(path, data = {}, callback) {
    return axios.put(path, data, { callback });
  }

  static delete(path, callback) {
    return axios.delete(path, { callback });
  }

  static patch(path, data = {}, callback) {
    return axios.patch(path, data, { callback });
  }

  static download(path){
    return axios({ url: path, method: 'GET', responseType: 'blob'});
  }
}
