import React, { useState } from "react";
import { useHistory } from "react-router";
import { PageHeader, Table, Tag, Button, Tooltip, Space, Drawer, Descriptions } from "antd";
import { PlusOutlined, EyeOutlined, EditOutlined } from '@ant-design/icons';
import UsersView from "../view/UsersView";

const DescItem = Descriptions.Item;

const UsersList = () => {
  const history = useHistory();
  const [visible, setVisible] = useState(false);
  const [currentItem, setCurrentItem] = useState(undefined);

  const columns = [
    { title: 'Nombre', dataIndex: 'name', key: 'name' },
    { title: 'Edad', dataIndex: 'age', key: 'age' },
    { title: 'Dirección', dataIndex: 'address', key: 'address' },
    { title: 'Permisos', key: 'permisos', dataIndex: 'permisos',
      render: permisos => (
        <>
          {permisos.map(permiso => {
            let color = permiso.includes('Ver') ? 'geekblue' 
              : permiso.includes('Eliminar') ? 'volcano' : 'green';
            return (
              <Tag color={color} key={permiso}>
                {permiso.toUpperCase()}
              </Tag>
            );
          })}
        </>
      ),
    },
    { key: 'actions',
      render: (text, record) => {
        return <Space size="small">
          <Tooltip title="Ver">
            <Button type="primary" shape="circle" size="small" icon={ <EyeOutlined /> }
              /*onClick={ () => history.push(history.location.pathname + '/view/' + record.id) }*/
              onClick={ () => showDrawer(record) }
            />
          </Tooltip>
          <Tooltip title="Editar">
            <Button shape="circle" size="small" icon={ <EditOutlined /> }
              onClick={ () => history.push(history.location.pathname + '/edit/' + record.id) }
            />
          </Tooltip>
        </Space>
      }
    }
  ];
  
  const data = [
    { key: '1', id: '1', name: 'John Brown', age: 32, address: 'New York No. 1 Lake Park', permisos: ['Ver Usuarios', 'Editar Usuarios'] },
    { key: '2', id: '2', name: 'Jim Green', age: 42, address: 'London No. 1 Lake Park', permisos: ['Eliminar Usuarios'] },
    { key: '3', id: '3', name: 'Joe Black', age: 32, address: 'Sidney No. 1 Lake Park', permisos: ['Ver Parametros', 'Editar Parametros'] },
    { key: '4', id: '4', name: 'John Brown', age: 32, address: 'New York No. 1 Lake Park', permisos: ['Ver Usuarios', 'Editar Usuarios'] },
    { key: '5', id: '5', name: 'Jim Green', age: 42, address: 'London No. 1 Lake Park', permisos: ['Eliminar Usuarios'] },
    { key: '6', id: '6', name: 'Joe Black', age: 32, address: 'Sidney No. 1 Lake Park', permisos: ['Ver Parametros', 'Editar Parametros'] },
    { key: '7', id: '7', name: 'John Brown', age: 32, address: 'New York No. 1 Lake Park', permisos: ['Ver Usuarios', 'Editar Usuarios'] },
    { key: '8', id: '8', name: 'Jim Green', age: 42, address: 'London No. 1 Lake Park', permisos: ['Eliminar Usuarios'] },
    { key: '9', id: '9', name: 'Joe Black', age: 32, address: 'Sidney No. 1 Lake Park', permisos: ['Ver Parametros', 'Editar Parametros'] },
    { key: '10', id: '10', name: 'John Brown', age: 32, address: 'New York No. 1 Lake Park', permisos: ['Ver Usuarios', 'Editar Usuarios'] },
    { key: '11', id: '11', name: 'Jim Green', age: 42, address: 'London No. 1 Lake Park', permisos: ['Eliminar Usuarios'] },
    { key: '12', id: '12', name: 'Joe Black', age: 32, address: 'Sidney No. 1 Lake Park', permisos: ['Ver Parametros', 'Editar Parametros'] },
    { key: '13', id: '13', name: 'John Brown', age: 32, address: 'New York No. 1 Lake Park', permisos: ['Ver Usuarios', 'Editar Usuarios'] },
    { key: '14', id: '14', name: 'Jim Green', age: 42, address: 'London No. 1 Lake Park', permisos: ['Eliminar Usuarios'] },
    { key: '15', id: '15', name: 'Joe Black', age: 32, address: 'Sidney No. 1 Lake Park', permisos: ['Ver Parametros', 'Editar Parametros'] },
    { key: '16', id: '16', name: 'John Brown', age: 32, address: 'New York No. 1 Lake Park', permisos: ['Ver Usuarios', 'Editar Usuarios'] },
    { key: '17', id: '17', name: 'Jim Green', age: 42, address: 'London No. 1 Lake Park', permisos: ['Eliminar Usuarios'] },
    { key: '18', id: '18', name: 'Joe Black', age: 32, address: 'Sidney No. 1 Lake Park', permisos: ['Ver Parametros', 'Editar Parametros'] },
  ];

  const showDrawer = item => {
    setVisible(true);
    setCurrentItem(item);
  };
  const onDrawerClose = () => {
    setVisible(false);
    setCurrentItem(undefined);
  };

  return (
    <div style={ { backgroundColor: '#fff', height: '100%', padding: 8 } }>
      <PageHeader
        className="site-page-header"
        title="Usuarios"
        subTitle="Listado"
        extra={[
          <Button key="1" type="primary" icon={ <PlusOutlined /> } 
            onClick={ () => history.push(history.location.pathname + '/new') }>
            Nuevo
          </Button>,
        ]}
      />
      <div style={ { padding: 20 } }>
        <Table
          size="small"
          columns={ columns } 
          dataSource={ data }
          pagination={ { hideOnSinglePage: true, pageSize: 10 } }
        />
      </div>

      <Drawer
        title={`Usuario ${currentItem?.name}`}
        placement="right"
        closable={ false }
        onClose={ onDrawerClose }
        visible={ visible }
        width={ 720 }
      >
        <UsersView 
          user={ currentItem }
        />
      </Drawer>
    </div>
  );
}

export default UsersList;

