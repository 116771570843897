import React, {useState,useEffect} from "react";
import { Drawer, Form, Button, Col, Row, Input, Select, DatePicker,Upload,message,InputNumber,Cascader } from 'antd';
import request from '../../../../../shared/helpers/request';
const { Dragger } = Upload;
const { Option } = Select;

const UploadDocumentosForm = (props) => {

  const [categoriasData, setCategoriasData] = useState([]);
  const [fileSeleccionado, setFileSeleccionado] = useState(null);

  useEffect(() => {
    const apiUrl = '/categorias/tree';
    request.get(apiUrl).then(response=>{
      setCategoriasData(response.data);
      console.log(response.data);
    });
  }, []);

  const onFileSelectionChange = () => {
    var file = document.getElementById('archivo').files[0];
    console.log(file);
  }

  const onSubmit = (data) => {
    data.archivo = document.getElementById('archivo').files[0];
    props.onSubmit(data);
  }

  return (
    <>
      <Drawer
        title="Subir Documentos"
        width={ 720 }
        onClose={props.onClose}
        visible={props.visible}
        bodyStyle={{ paddingBottom: 80 }}
        footer={
          <div
            style={{
              textAlign: 'right',
            }}
          >
            <Button onClick={props.onClose} style={{ marginRight: 8 }}>
              Cancel
            </Button>
            <Button form="uploadForm"  type="primary" key="submit" htmlType="submit">
              Subir
            </Button>
          </div>
        }
      >
        <Form id='uploadForm' layout="vertical" hideRequiredMark  onFinish={onSubmit}>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="anio"
                label="Año"
                rules={[{ required: true, message: 'Debe ingresar el año' }]}
              >
                <InputNumber min={1} max={1000000} style={{ width: '100%' }} placeholder="Por favor ingrese el año del documento"/>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="numero"
                label="Numero"
                rules={[{ required: true, message: 'Debe ingresar el Numero' }]}
              >
                <InputNumber min={1} max={1000000} style={{ width: '100%' }} placeholder="Por favor ingrese el numero del documento"/>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="facultad"
                label="Dependencia"
                rules={[{ required: true, message: 'Por favor seleccione una dependencia' }]}
              >
                <Select
                  allowClear
                  showSearch
                  placeholder="Seleccione una dependencia"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <Option value="CSU">
                    Consejo Superior            
                  </Option>
                  <Option value="AU">
                    Asamblea Universitaria            
                  </Option>
                  <Option value="CNAOP">
                    Com Nac Aprendizaje y Orientacion Prof            
                  </Option>
                  <Option value="CRUN">
                    Consejo de Rectores de Univer. Nac.            
                  </Option>
                  <Option value="CSUBC">
                    Interventor Carlos Burandarena            
                  </Option>
                  <Option value="CSUCC">
                    Interventor Cecilio Conditi            
                  </Option>
                  <Option value="CSUCE">
                    Delegado Militar Com. Echenique            
                  </Option>
                  <Option value="CSUCI">
                    Interventor Iván Chambouleyron            
                  </Option>
                  <Option value="CSUCO">
                    Interventor Omar Conca            
                  </Option>
                  <Option value="CSUCOA">
                    Comisión De Adm. Y Presup. Del CSU            
                  </Option>
                  <Option value="CSUCOE">
                    Comisión De Enseñanza Del CSU            
                  </Option>
                  <Option value="CSUCOI">
                    Comisión De Int.y Reglamento Del CSU            
                  </Option>
                  <Option value="CSUCOP">
                    Comisión De Planeamiento Del CSU            
                  </Option>
                  <Option value="CSUGR">
                    Interventor Roberto Guillán            
                  </Option>
                  <Option value="CSUMA">
                    Interventor Alberto Montes            
                  </Option>
                  <Option value="CSUPT">
                    Interventor Tomás Persichini            
                  </Option>
                  <Option value="CSUSC">
                    Interventor Carmelo Soriano            
                  </Option>
                  <Option value="CSUWR">
                    Interventor Rolando Weidembach            
                  </Option>
                  <Option value="DASUTE">
                    DASUTeN            
                  </Option>
                  <Option value="DGA">
                    Dirección General Administracion            
                  </Option>
                  <Option value="FRA">
                    Facultad Regional Avellaneda            
                  </Option>
                  <Option value="FRBA">
                    Facultad Regional Buenos Aires            
                  </Option>
                  <Option value="FRBB">
                    Facultad Regional Bahía Blanca            
                  </Option>
                  <Option value="FRC">
                    Unidad Académica Concordia            
                  </Option>
                  <Option value="FRCU">
                    Facultad Regional Concepción del Urugua            
                  </Option>
                  <Option value="FRD">
                    Facultad Regional Delta            
                  </Option>
                  <Option value="FRGP">
                    Facultad Regional General Pacheco            
                  </Option>
                  <Option value="FRH">
                    Facultad Regional Haedo            
                  </Option>
                  <Option value="FRLP">
                    Facultad Regional La Plata            
                  </Option>
                  <Option value="FRM">
                    Facultad Regional Mendoza            
                  </Option>
                  <Option value="FRP">
                    Facultad Regional Paraná            
                  </Option>
                  <Option value="FRRA">
                    Facultad Regional Rafaela            
                  </Option>
                  <Option value="FRRE">
                    Facultad Regional Resistencia            
                  </Option>
                  <Option value="FRRG">
                    Facultad Regional Río Grande            
                  </Option>
                  <Option value="FRRO">
                    Facultad Regional Rosario            
                  </Option>
                  <Option value="FRSF">
                    Facultad Regional Santa Fe            
                  </Option>
                  <Option value="FRSFCO">
                    Facultad Regional San Francisco            
                  </Option>
                  <Option value="FRSN">
                    Facultad Regional San Nicolás            
                  </Option>
                  <Option value="FRSR">
                    Facultad Regional San Rafael            
                  </Option>
                  <Option value="FRT">
                    Facultad Regional Tucumán            
                  </Option>
                  <Option value="FRVM">
                    Facultad Regional Villa María            
                  </Option>
                  <Option value="FRVT">
                    Facultad Regional Venado Tuerto            
                  </Option>
                  <Option value="MCYE">
                    Ministerio de Cultura y Educacion            
                  </Option>
                  <Option value="PEN">
                    Poder Ejecutivo Nacional            
                  </Option>
                  <Option value="PLN">
                    Poder Legislativo Nacional            
                  </Option>
                  <Option value="REC">
                    Rectorado            
                  </Option>
                  <Option value="SAD">
                    Secretaria Administrativa            
                  </Option>
                  <Option value="SECYT">
                    Sec. De Ciencia Y Tecnología. (PEN)            
                  </Option>
                  <Option value="SESUP">
                    Sec. De Educación Superior  (MCYE)            
                  </Option>
                  <Option value="SFP">
                    Secretaría de la Funcion Pública            
                  </Option>
                  <Option value="UAC">
                    Unidad Académica Concordia            
                  </Option>
                  <Option value="UACH">
                    Regional Académica Chubut            
                  </Option>
                  <Option value="UACO">
                    Unidad Academica Confluencia            
                  </Option>
                  <Option value="UALR">
                    Unidad Académica La Rioja            
                  </Option>
                  <Option value="UARG">
                    Unidad Académica Río Gallegos            
                  </Option>
                  <Option value="UARQTA">
                    Unidad Académica Reconquista            
                  </Option>
                  <Option value="UATL">
                    Unidad Académica Trenque Launquen            
                  </Option>
                  <Option value="UAVT">
                    Unidad Academica Venado Tuerto            
                  </Option>
                  <Option value="UBA">
                    Universidad De Buenos Aires            
                  </Option>
                  <Option value="VRAC">
                    Vicerector A Cargo            
                  </Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="tipo"
                label="Tipo de Documento"
                rules={[{ required: true, message: 'Por favor seleccione un tipo de documento' }]}
              >
                <Select
                  showSearch
                  allowClear
                  placeholder="Tipo de Documento"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <Option value="ACT">Acta</Option>
                  <Option value="CIR">Circular</Option>
                  <Option value="COM">Comunicacion</Option>
                  <Option value="DEC">Declaracion</Option>
                  <Option value="DES">Despacho</Option>
                  <Option value="DIS">Disposicion</Option>
                  <Option value="DTO">Decreto</Option>
                  <Option value="LEY">Ley</Option>
                  <Option value="NTA">Nota</Option>
                  <Option value="ORD">Ordenanza</Option>
                  <Option value="REC">Recomendacion</Option>
                  <Option value="RES">Resolucion</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="fecha"
                label="Fecha"
                rules={[{ required: true, message: 'Seleccione la fecha' }]}
              >
                <DatePicker
                  style={{ width: '100%' }}
                  getPopupContainer={trigger => trigger.parentElement}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
            <Form.Item
                name="archivo"
                label="Archivo"
              >
                <Input type="file" onChange={onFileSelectionChange}/>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="categorias"
                label="Categorias"
                rules={[
                  {
                    required: true,
                    message: 'Debe seleccionar una categoria',
                  },
                ]}
              >
                <Cascader 
                  options={categoriasData}  
                  placeholder="Seleccione la Categoria " 
                  fieldNames={{ label: 'title', value: 'id', children: 'children' }}

                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="tematica"
                label="Tematica"
                rules={[
                  {
                    required: true,
                    message: 'Debe ingresar la tematica',
                  },
                ]}
              >
                <Input.TextArea rows={4} placeholder="Por favor ingrese la tematica del documento" />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Drawer>
    </>
  );
};

export default UploadDocumentosForm;
