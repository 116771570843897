import React, { useState } from "react";
import { Row, Col, Form, Input, Button,Result,Select,PageHeader,Card } from "antd";
import request from '../../../../../shared/helpers/request';
import ResultadoBusqueda from './shared/ResultadoBusqueda';

const { Option } = Select;

function BusquedaPorCategoriasTab(props) {
  const [form] = Form.useForm();
  const [datosBusqueda, setDatosBusqueda] = useState([]);
  const [primeraBusqueda, setPrimeraBusqueda] = useState(false);
  

  const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 14 },
  };

  const buttonItemLayout ={
    wrapperCol: { span: 14, offset: 4 },
  };

  const onFinish = (values) => {   
    const apiUrl = `/documentos/busqueda-avanzada?txtTipoDocumento=${(values.tipo_documento || '')}&txtNumero=${(values.numero || '')}&txtAnio=${(values.anio || '')}&txtDependencia=${(values.dependencia || '')}`;
    request.get(apiUrl).then(response=>{
      setPrimeraBusqueda(true);
      setDatosBusqueda(response.data);
    });
  }; 

  return (
    <div style={{ height: "100%" }}>
      <Card style={{ backgroundColor:'#f2f2f0' }}  >
      <div className="site-card-wrapper">
      <PageHeader
    className="site-page-header"
    onBack={() => props.onChangeTab('simple')}
    title="Busqueda Avanzada"
    subTitle=""
  />
      <Form
            name="validate_other"
            {...formItemLayout}
            form={form}
            onFinish={onFinish}
            size={'large'}
          >
           
        <Row gutter={24}>
       
          <Col span={24} >
          
            <Form.Item
              name="tipo_documento"
              label="Tipo de Documento"
            >
              <Select
                showSearch
                allowClear
                placeholder="Tipo e Documento"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                <Option value="ACT">Acta</Option>
                <Option value="CIR">Circular</Option>
                <Option value="COM">Comunicacion</Option>
                <Option value="DEC">Declaracion</Option>
                <Option value="DES">Despacho</Option>
                <Option value="DIS">Disposicion</Option>
                <Option value="DTO">Decreto</Option>
                <Option value="LEY">Ley</Option>
                <Option value="NTA">Nota</Option>
                <Option value="ORD">Ordenanza</Option>
                <Option value="REC">Recomendacion</Option>
                <Option value="RES">Resolucion</Option>
              </Select>
            </Form.Item>
            <Form.Item label="Numero" name="numero">
              <Input type={'number'} />
            </Form.Item>
            <Form.Item type={'number'} label="Año" name="anio">
              <Input type={'number'} />
            </Form.Item>
            <Form.Item
              name="dependencia"
              label="Dependencia"
            >
              <Select
                allowClear
                showSearch
                placeholder="Seleccione una dependencia"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                 <Option value="CSU">
                  Consejo Superior            
                </Option>
                <Option value="AU">
                  Asamblea Universitaria            
                </Option>
                <Option value="CNAOP">
                  Com Nac Aprendizaje y Orientacion Prof            
                </Option>
                <Option value="CRUN">
                  Consejo de Rectores de Univer. Nac.            
                </Option>
                <Option value="CSUBC">
                  Interventor Carlos Burandarena            
                </Option>
                <Option value="CSUCC">
                  Interventor Cecilio Conditi            
                </Option>
                <Option value="CSUCE">
                  Delegado Militar Com. Echenique            
                </Option>
                <Option value="CSUCI">
                  Interventor Iván Chambouleyron            
                </Option>
                <Option value="CSUCO">
                  Interventor Omar Conca            
                </Option>
                <Option value="CSUCOA">
                  Comisión De Adm. Y Presup. Del CSU            
                </Option>
                <Option value="CSUCOE">
                  Comisión De Enseñanza Del CSU            
                </Option>
                <Option value="CSUCOI">
                  Comisión De Int.y Reglamento Del CSU            
                </Option>
                <Option value="CSUCOP">
                  Comisión De Planeamiento Del CSU            
                </Option>
                <Option value="CSUGR">
                  Interventor Roberto Guillán            
                </Option>
                <Option value="CSUMA">
                  Interventor Alberto Montes            
                </Option>
                <Option value="CSUPT">
                  Interventor Tomás Persichini            
                </Option>
                <Option value="CSUSC">
                  Interventor Carmelo Soriano            
                </Option>
                <Option value="CSUWR">
                  Interventor Rolando Weidembach            
                </Option>
                <Option value="DASUTE">
                  DASUTeN            
                </Option>
                <Option value="DGA">
                  Dirección General Administracion            
                </Option>
                <Option value="FRA">
                  Facultad Regional Avellaneda            
                </Option>
                <Option value="FRBA">
                  Facultad Regional Buenos Aires            
                </Option>
                <Option value="FRBB">
                  Facultad Regional Bahía Blanca            
                </Option>
                <Option value="FRC">
                  Unidad Académica Concordia            
                </Option>
                <Option value="FRCU">
                  Facultad Regional Concepción del Urugua            
                </Option>
                <Option value="FRD">
                  Facultad Regional Delta            
                </Option>
                <Option value="FRGP">
                  Facultad Regional General Pacheco            
                </Option>
                <Option value="FRH">
                  Facultad Regional Haedo            
                </Option>
                <Option value="FRLP">
                  Facultad Regional La Plata            
                </Option>
                <Option value="FRM">
                  Facultad Regional Mendoza            
                </Option>
                <Option value="FRP">
                  Facultad Regional Paraná            
                </Option>
                <Option value="FRRA">
                  Facultad Regional Rafaela            
                </Option>
                <Option value="FRRE">
                  Facultad Regional Resistencia            
                </Option>
                <Option value="FRRG">
                  Facultad Regional Río Grande            
                </Option>
                <Option value="FRRO">
                  Facultad Regional Rosario            
                </Option>
                <Option value="FRSF">
                  Facultad Regional Santa Fe            
                </Option>
                <Option value="FRSFCO">
                  Facultad Regional San Francisco            
                </Option>
                <Option value="FRSN">
                  Facultad Regional San Nicolás            
                </Option>
                <Option value="FRSR">
                  Facultad Regional San Rafael            
                </Option>
                <Option value="FRT">
                  Facultad Regional Tucumán            
                </Option>
                <Option value="FRVM">
                  Facultad Regional Villa María            
                </Option>
                <Option value="FRVT">
                  Facultad Regional Venado Tuerto            
                </Option>
                <Option value="MCYE">
                  Ministerio de Cultura y Educacion            
                </Option>
                <Option value="PEN">
                  Poder Ejecutivo Nacional            
                </Option>
                <Option value="PLN">
                  Poder Legislativo Nacional            
                </Option>
                <Option value="REC">
                  Rectorado            
                </Option>
                <Option value="SAD">
                  Secretaria Administrativa            
                </Option>
                <Option value="SECYT">
                  Sec. De Ciencia Y Tecnología. (PEN)            
                </Option>
                <Option value="SESUP">
                  Sec. De Educación Superior  (MCYE)            
                </Option>
                <Option value="SFP">
                  Secretaría de la Funcion Pública            
                </Option>
                <Option value="UAC">
                  Unidad Académica Concordia            
                </Option>
                <Option value="UACH">
                  Regional Académica Chubut            
                </Option>
                <Option value="UACO">
                  Unidad Academica Confluencia            
                </Option>
                <Option value="UALR">
                  Unidad Académica La Rioja            
                </Option>
                <Option value="UARG">
                  Unidad Académica Río Gallegos            
                </Option>
                <Option value="UARQTA">
                  Unidad Académica Reconquista            
                </Option>
                <Option value="UATL">
                  Unidad Académica Trenque Launquen            
                </Option>
                <Option value="UAVT">
                  Unidad Academica Venado Tuerto            
                </Option>
                <Option value="UBA">
                  Universidad De Buenos Aires            
                </Option>
                <Option value="VRAC">
                  Vicerector A Cargo            
                </Option>
              </Select>
            </Form.Item>
            </Col>
            <Col span={20} style={{textAlign:'center', marginBottom:'2rem'}}  >
           
              <Button style={{ fontSize:'118%'}} type="primary" htmlType="submit">
                Buscar
              </Button>
              <Button style={{ fontSize:'118%',marginLeft:'1rem'}} type="default" onClick={()=>{props.onChangeTab('simple')}}  >
                Volver
              </Button>
            
            </Col>
        
          
          <Col span={24}  >
            <ResultadoBusqueda primeraBusqueda = {primeraBusqueda} resultadosBusqueda={ datosBusqueda } userData ={ props.userData }/>
          </Col>
        </Row>
       
        </Form>
      </div>
      </Card>
    </div>
  );
}

export default BusquedaPorCategoriasTab;
