import React from "react";
import { useHistory } from "react-router";
import background from './assets/bg-login.jpg';
import './ModulesPage.scss';
import ModulesList from "./components/ModulesList";
import { Button } from "antd";
import AuthenticationHelper from "../../shared/helpers/authenticationHelper";

const ModulesPage = () => {
  const history = useHistory();

  const handleSalirButton = () => {
    AuthenticationHelper.logout(() => history.push("/login"));
  }

  return (
    <div style={ { height:'100vh' } }>
      <div className="modules-viewport" style={ { backgroundImage: `url(${background})` } }>
        <div className="modules-viewport-cover" />
      </div>

      <div className="modules-main">
        <div className="title">
          <span>Estos son los <b>sistemas habilitados</b> para su usuario</span>
        </div>
        <div>
          <ModulesList />
        </div>
        <Button type="primary" block onClick={ handleSalirButton }>Salir</Button>
      </div>
    </div>
  )
}

export default ModulesPage;