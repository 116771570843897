
import React, { useState } from "react";
import { Layout } from "antd";
import LoginForm  from './components/login/LoginForm';
import UploadForm from './components/uploadForm/UploadForm';


const { Content } = Layout;

function UploadDocumento(props) {
  const { show } = props;

  const renderForm = () => {
    if(true){
      return <LoginForm show={ show }/>
    }else{
      return <UploadForm show={ show }/>
    }
  }
  
  return (
    <Content
      style={{
        margin: "12px 12px",
        padding: 0,
        background: "#EBEDEF ",
      }}
    >
      <div> { renderForm() }</div>
    </Content>
  );
}
 
export default UploadDocumento;