
import React, {useState} from "react";
import { Tabs, Button, Layout, Tooltip, notification ,PageHeader} from "antd";
import {
  CloudUploadOutlined,
  FileSearchOutlined,
} from "@ant-design/icons";


import './HomePage.scss';
import './css/bootstrap.min.css'; // eslint-disable-line no-unused-vars
import './css/default.css'; // eslint-disable-line no-unused-vars
import './css/template.css'; // eslint-disable-line no-unused-vars

import BusquedaTab from './components/busquedaTab/BusquedaTab';
import HomePageHeader from './HomePageHeader';
import HomePageFooter from './HomePageFooter';

import UploadDocumento from './components/uploadDocumento/UploadDocumento'
const { TabPane } = Tabs;
const { Content } = Layout;

function HomePage() {
  const [openDrawer, setDrawerStatus] = useState(false);
  
  const botonEditarDosificador = (
    <div style={{ marginRight: 10 }}>
      <Tooltip title="Ingresar Documentos">
        <Button
          type="primary"
          shape="circle"
          icon={<CloudUploadOutlined />}
          size={'small'}
          onClick={()=>setDrawerStatus(true)}
        />
      </Tooltip>
    </div>
  );
  
  return (
    <div>
      <HomePageHeader/>
      <Content
        style={{
          margin: "12px 12px",
          padding: 0,
          background: "#EBEDEF ",
        }}
      >
        <BusquedaTab/>
      </Content>
      <HomePageFooter/>
    </div>
  );
}
 
export default HomePage;