import React, { useState } from 'react';
import { Layout, Menu } from 'antd';
import { Link } from "react-router-dom";
import logo from '../../assets/logo-cpa.png';
import './AppLeftMenu.scss';

import Icon, { HomeOutlined, ControlOutlined } from "@ant-design/icons";
const { Sider } = Layout;
const { SubMenu } = Menu;
const MenuItem = Menu.Item;

const AppLeftMenu = () => {
  const [collapsed, onCollapse] = useState(false);

  const menuitems = [
    { key: '1', icon: HomeOutlined, link: '/administration', title: 'Usuarios' },
    { key: '2', icon: ControlOutlined, link: '/administration/system-vars', title: 'Parametros globales' },
  ]

  function renderMenuItem(item){
    if(item.children && item.children.length > 0){
      return <SubMenu key={ item.key } icon={ <Icon component={ item.icon } /> } title={ item.title }>
        { item.children.map(child => renderMenuItem(child)) }
      </SubMenu>;
    }
    else{
      return <MenuItem key={ item.key } icon={ <Icon component={ item.icon } /> }>
        <Link to={ item.link } /><span>{ item.title }</span>
      </MenuItem>
    }
  }

  return (
    <Sider className="left-menu-main" collapsible collapsed={ collapsed } onCollapse={ onCollapse }>
      <div className="app-layout-logo" >
        <img src={ logo } alt="logo" className="logoImg" />
      </div>

      <Menu theme="dark" defaultSelectedKeys={ ["1"] } mode="inline">
        { menuitems.map(item => renderMenuItem(item)) }
      </Menu>
    </Sider>
  );
}

export default AppLeftMenu;
