import React, {useEffect,useState} from "react";
import { Row, Col, Button,Tag, message,Modal } from "antd";
import { EyeFilled } from '@ant-design/icons';
import LoadingIcon from "./customIcons/LoadingIcon";
import request from '../../../../../../shared/helpers/request';
import DocumentRelationsViewer from "./DocumentRelationsViewer";
import {
  FileSearchOutlined,
  ExceptionOutlined,
  DeleteOutlined,
  CloudDownloadOutlined,
  ExclamationCircleOutlined
} from "@ant-design/icons";
import { useLocation,useHistory,Link } from "react-router-dom";

const { confirm } = Modal;

const tipos = {
  'ORD':'Ordenanza',
  'LEY':'Ley',
  'DES':'Despacho',
  'DTO':'Decreto',
  'RES':'Resolución',
  'REC':'Recomendación',
  'DEC':'Declaración',
  'CIR':'Circular',
  'ACT':'Acta',
  'DIS':'Disposicion',
  'COM':'Comunicación',
  'NTA':'Nota'
}

const facultad = {
  'FRLP':	'Facultad Regional La Plata',
  'FRSF':	'Facultad Regional Santa Fe',
  'CRUN':	'Consejo de Rectores de Univer. Nac.',
  'MCYE':	'Ministerio de Cultura y Educacion',
  'UACO':	'Unidad Academica Confluencia',
  'UAVT':	'Unidad Academica Venado Tuerto',
  'CSUMA':	'Interventor Alberto Montes',
  'SESUP'	:'Sec. De Educación Superior  (MCYE)',
  'SECYT':	'Sec. De Ciencia Y Tecnología. (PEN)',
  'PEN':	'Poder Ejecutivo Nacional',
  'PLN':	'Poder Legislativo Nacional',
  'UBA':	'Universidad De Buenos Aires',
  'REC':	'Rectorado',
  'SFP':	'Secretaría de la Funcion Pública',
  'CNAOP':	'Com Nac Aprendizaje y Orientacion Prof',
  'AU':	'Asamblea Universitaria',
  'DGA':	'Dirección General Administracion',
  'CSUWR':	'Interventor Rolando Weidembach',
  'SAD':	'Secretaria Administrativa',
  'CSUPT':	'Interventor Tomás Persichini',
  'CSUCC':	'Interventor Cecilio Conditi',
  'CSUSC':	'Interventor Carmelo Soriano',
  'CSUCE':	'Delegado Militar Com. Echenique',
  'CSUCO':	'Interventor Omar Conca',
  'CSUBC':	'Interventor Carlos Burandarena',
  'CSUGR':	'Interventor Roberto Guillán',
  'CSUCI':	'Interventor Iván Chambouleyron',
  'CSUCOE':	'Comisión De Enseñanza Del CSU',
  'CSUCOA':	'Comisión De Adm. Y Presup. Del CSU',
  'CSUCOP':	'Comisión De Planeamiento Del CSU',
  'CSUCOI':	'Comisión De Int.y Reglamento Del CSU',
  'FRGP':	'Facultad Regional General Pacheco',
  'FRVM':	'Facultad Regional Villa María',
  'FRVT':	'Facultad Regional Venado Tuerto',
  'UATL':	'Unidad Académica Trenque Launquen',
  'FRBA':	'Facultad Regional Buenos Aires',
  'FRBB':	'Facultad Regional Bahía Blanca',
  'FRRA':	'Facultad Regional Rafaela',
  'FRA':	'Facultad Regional Avellaneda',
  'FRRE':	'Facultad Regional Resistencia',
  'FRD':	'Facultad Regional Delta',
  'FRRG':	'Facultad Regional Río Grande',
  'UALR':	'Unidad Académica La Rioja',
  'FRH':	'Facultad Regional Haedo',
  'FRCU':	'Facultad Regional Concepción del Uruguay',
  'FRM':	'Facultad Regional Mendoza',
  'FRRO':	'Facultad Regional Rosario',
  'FRP':	'Facultad Regional Paraná',
  'FRT':	'Facultad Regional Tucumán',
  'FRSN':	'Facultad Regional San Nicolás',
  'FRSR':	'Facultad Regional San Rafael',
  'UAC':	'Unidad Académica Concordia',
  'UARG':	'Unidad Académica Río Gallegos',
  'UARQTA':	'Unidad Académica Reconquista',
  'FRSFCO':	'Facultad Regional San Francisco',
  'CSU':	'Consejo Superior',
  'UACH':	'Regional Académica Chubut',
  'FRC':	'Unidad Académica Concordia',
  'VRAC':	'Vicerector A Cargo',
  'DASUTE':	'DASUTeN'
}

const ResultadoBusqueda = (props) => {
  const url = new URLSearchParams(useLocation().search).get("documentoId");
  const [documentoSeleccionado, seleccionarDocumento] = useState({});
  const [documentosRelacionados, setDocumentosRelacionados] = useState({});
  const [showDocumentosRelacionados, setShowDocumentosRelacionados] = useState(false);
  const [documentIdToBeShowed,setDocumentIdToBeShowed] = useState(url);

  const history = useHistory();
  //const urlGetParams= new URLSearchParams(useLocation().search);
  //const documentIdToBeShowed = urlGetParams.get("documentoId");
  
  const onDocumentRelationsClose = () => {
    setShowDocumentosRelacionados(false);
  }

/*
  useEffect(() => {
    if(props.resultadosBusqueda.length > 0) {
      history.push('/home');
      setDocumentIdToBeShowed(null);
    }
      
  }, [props.resultadosBusqueda] );

  useEffect(() => {
    if(url !==  documentIdToBeShowed)
    setDocumentIdToBeShowed(url);
  } );
*/


  const  showDocument = (documentoSeleccionado) => {
    seleccionarDocumento(documentoSeleccionado);
    const {facultad, tipo, anio, numero} = documentoSeleccionado;
    const id =  `${facultad}**${tipo}**${anio}**${numero}`;
    const fileUri =`${window.location.protocol}//${window.location.host}/api/documentos/get-pdf-base64?documentoId=${id}`
    try {
      request
        .get(fileUri, {
          responseType: "arraybuffer",
        })
        .then((response) => {
          var objbuilder = '';
          objbuilder += ('<object width="100%" height="100%" data="data:application/pdf;base64,');
          objbuilder += (response.data.data);
          objbuilder += ('" type="application/pdf" class="internal">');
          objbuilder += ('<embed src="data:application/pdf;base64,');
          objbuilder += (response.data.data);
          objbuilder += ('" type="application/pdf"  />');
          objbuilder += ('</object>');
          var win = window.open("#","_blank");
          var title = "Imprimir Documento";
          win.document.write('<html><title>'+ title +'</title><body style="margin-top: 0px; margin-left: 0px; margin-right: 0px; margin-bottom: 0px;">');
          win.document.write(objbuilder);
          win.document.write('</body></html>');
        })
        .catch((error) => {
          console.error(error);
        });
    } catch (error) {
      return { error };
    }
  }

  const downloadDocument = (documentoSeleccionado) =>{
    seleccionarDocumento(documentoSeleccionado);
    const {facultad, tipo, anio, numero} = documentoSeleccionado;
    const id =  `${facultad}**${tipo}**${anio}**${numero}`;
    const fileUri =`${window.location.protocol}//${window.location.host}/api/documentos/get-pdf-base64?documentoId=${id}`
    try {
      request
        .get(fileUri, {
          responseType: "arraybuffer",
        })
        .then((response) => {

          const linkSource = `data:application/pdf;base64,${response.data.data}`;
          const downloadLink = document.createElement("a");
          const fileName = id.replace('**','_')+".pdf";
          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
        })
        .catch((error) => {
          console.error(error);
        });
    } catch (error) {
      return { error };
    }

  }



  const  deleteDocument = (documentoSeleccionado) => {
    seleccionarDocumento(documentoSeleccionado);
    const {facultad, tipo, anio, numero} = documentoSeleccionado;
    const id =  `${facultad}**${tipo}**${anio}**${numero}`;
    const uri =`${window.location.protocol}//${window.location.host}/api/documentos/eliminar?documentoId=${id}`
    try {
      confirm({
        icon: <ExclamationCircleOutlined />,
        title:'Confirmacion',
        content: 'Seguro que desea Eliminar este Documento?',
        onOk() {
          request
          .delete(uri)
          .then((response) => {
            if(response.data.status === 'OK'){
              message.success('Documento Eliminado');
            }
            if(response.data.status === 'Error'){
              message.error(response.data.message);
            }
          })
          .catch((error) => {
            console.error(error);
          });
        },
        onCancel() {
          message.info('Accion Cancelada');
        },
      });
    } catch (error) {
      return { error };
    }
  }

  const renderActionButtons = item => {
    const buttons = []; 
    buttons.push(<Button style={{ marginRight:'7px' }} size={'small'} type="primary" onClick={()=>{ history.push(`/home?documentoId=${item.facultad}**${item.tipo}**${item.anio}**${item.numero}`);}} >Detalle</Button>);
    buttons.push(<Button style={{ marginRight:'7px' }} size={'small'} type="primary" onClick={()=>showDocument(item)} icon={ <EyeFilled /> }>Ver Documento</Button>);
    buttons.push(<Button style={{ marginRight:'7px' }} size={'small'} type="primary" onClick={()=>downloadDocument(item)} icon={<CloudDownloadOutlined />}>Descargar</Button>);
    
    if(props.userData !== null)
      buttons.push(<Button type="danger" onClick={()=>deleteDocument(item)} icon={ <DeleteOutlined/> }>Delete</Button>);
    return buttons;
  }

  const renderDatosBusqueda = () =>{
    
    if(props.buscando) {
    return  (<div style={{height:500, textAlign:'center',paddingTop:'3rem'}}>
      < LoadingIcon />
      <div style={{ fontSize:"130%" }}>...cargando</div>
    </div>)

    } else if(props.resultadosBusqueda.length === 0 && props.primeraBusqueda){
      return (
        <div style={{height:500, textAlign:'center',paddingTop:'3rem'}}>
          <ExceptionOutlined style={{fontSize:100, color: 'orange'}}/>
          <div style={{ fontSize:"130%" }}>No se encontraron resultados</div>
        </div>
      );
    } else if(props.resultadosBusqueda.length === 0){
      return  <div style={{height:500, textAlign:'center',paddingTop:'3rem'}}>
      <FileSearchOutlined style={{fontSize:100, color: '#C8C8C8'}}/>
      <div style={{ fontSize:"130%" }}>Por favor, realice su búsqueda</div>
      
    </div>
    } else {
      return (
        <ul className="list-group list-group-flush">
          { props.resultadosBusqueda && props.resultadosBusqueda.map( item => {
            return (
              <li className="list-group-item">
                <div style={{ position:'relative',fontSize:'112%' }} >
                <Link to={`/home?documentoId=${item.facultad}**${item.tipo}**${item.anio}**${item.numero}`}><h6 style={{ fontSize:'108%'}}> { tipos[item.tipo] } de { facultad[item.facultad] }   <Tag color="#87d068">{item.numero}</Tag> {item.anio ? <span>/ <Tag color="#2db7f5">{item.anio}</Tag></span> : <span/>}</h6></Link>
                  { item.tematica }
                  <div style={{ float:'right' }} >
                    { renderActionButtons(item) }
                  </div>
                </div> 
              </li>
            )
          })}
        </ul>
      )
    }
  }

  const renderBody = () => {
    if(false) {
   // if(documentIdToBeShowed != null &&  !props.buscando ){
      // urlGetParams.delete("documentoId");
      // history.replace({
      //   search: urlGetParams.toString(),
      // });
     
      return <DocumentRelationsViewer documentoId = {documentIdToBeShowed}/>;
    }else{
      return renderDatosBusqueda();
    }
  }

  return (
    <div>
      <div style={{ backgroundColor:'white' }} className="site-card-wrapper">
        <Row justify={'center'}>
          <Col span={24} xl={24}>
            { renderBody() }
          </Col>
        </Row>
      </div>
      { /*
      <DocumentRelationsViewer 
        visible = { showDocumentosRelacionados } 
        documentoSeleccionado = { documentoSeleccionado } 
        documentosRelacionados = { documentosRelacionados } 
        onClose = { onDocumentRelationsClose } 
      />
      */
      }
    </div>
  );
};

export default ResultadoBusqueda;
