import React from "react";
import { LoadingOutlined } from '@ant-design/icons';
function LoadingIcon() {
  return (
    <div>
      <LoadingOutlined style={{fontSize:60 ,color:'#143873'}}/>
    </div>
  );
}
 
export default LoadingIcon;