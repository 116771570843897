import React, { useState } from "react";
import { Row, Col, Tree, Alert, Divider,Result,PageHeader } from "antd";
import request from '../../../../../shared/helpers/request';
import ResultadoBusqueda from './shared/ResultadoBusqueda';

function BusquedaPorCategoriasTab(props) {

  const [treeData, setTreeData] = useState([]);
  const [datosBusqueda, setDatosBusqueda] = useState([]);
  const [primeraBusqueda, setPrimeraBusqueda] = useState(false);
  
  const onSelect = (selectedKeys, info) => {
    console.log('selected', selectedKeys, info);
    const apiUrl = '/documentos/por-categoria?categoriaId='+selectedKeys;
    request.get(apiUrl).then(response=>{
      setPrimeraBusqueda(true);
      setDatosBusqueda(response.data);
    });
  };

  if( treeData.length === 0 ){
    const apiUrl = '/categorias/tree';
    request.get(apiUrl).then(response=>{
      setTreeData(response.data);
    });
  }

  return (
    <div style={{ height: "100%" }}>
      <div className="site-card-wrapper">
      <PageHeader
    className="site-page-header"
    onBack={() => props.onChangeTab('simple')}
    title="Busqueda por Categorias"
    subTitle=""
  />
        <Row justify='center' >
          <Col md={4} span={24}  >
            <Alert message="Seleccione la Categoria" type="info" />
            <Tree
              showLine = { true }
              showIcon = { true }
              defaultExpandedKeys = { ['01'] }
              onSelect = { onSelect }
              treeData = {  treeData }
            />
            <Divider type="vertical" />
          </Col>
          <Col md={16} span={24}  >
            <ResultadoBusqueda primeraBusqueda={ primeraBusqueda } resultadosBusqueda={ datosBusqueda } userData ={ props.userData }/>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default BusquedaPorCategoriasTab;
