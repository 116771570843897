import React, { Component } from 'react';

class HomePageFooter extends Component {

  render() {
    return (
      <section id="sp-bottom">
        <div className="container">
          <div className="container-inner">
            <div className="row">
              <div id="sp-bottom1" className="col-lg-2 ">
                <div className="sp-column ">
                  <div className="sp-module ">
                    <div className="sp-module-content">
                      <div className="custom">
                        <p><img src="https://utn.edu.ar/images/logoutnwhite.png" alt="" width="189" height="49" /></p>
                        <p style={ { lineHeight: '0.7em', fontSize: '0.85em' } }>UTN | Rectorado</p>
                        <p style={ { lineHeight: '0.7em', fontSize: '0.85em' } }><a>Sarmiento 440&nbsp;(C1041AAJ)</a></p>
                        <p style={ { lineHeight: '0.7em', fontSize: '0.85em' } }><a>Buenos Aires, Argentina</a></p>
                        <p style={ { lineHeight: '0.7em', fontSize: '0.85em' } }><a href="tel: +54 11 5371 5600">+54 11 5371 5600</a></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div id="sp-bottom2" className="col-sm-col-sm-6 col-lg-7 ">
                <div className="sp-column ">
                  <div className="sp-module ">
                    <div className="sp-module-content">
                    <div className="custom">
                      <div className="row">
                        <div className="col-md-8">
                          <h6 style={{fontWeight: 400,color:'white'}}>SECRETARÍAS</h6>
                          <div className="row">
                            <div className="col-md-6 mt-2" style={{fontSize: '90%'}}>
                              <ul>
                                <li className="footer-list__item" style={{lineHeight: '0.9em'}}><a target="blank" href="https://utn.edu.ar/es/secretaria-general/sgral-institucional">General</a></li>
                                <li className="footer-list__item" style={{lineHeight: '0.9em'}}><a target="blank" href="https://utn.edu.ar/es/es/secretaria-academica/sacad-inicio">Académica</a></li>
                                <li className="footer-list__item" style={{lineHeight: '0.9em'}}><a target="blank" href="https://utn.edu.ar/es/es/sadm-inicio/sadm-home-inicio">Administrativa</a></li>
                                <li className="footer-list__item" style={{lineHeight: '0.9em'}}><a target="blank" href="https://utn.edu.ar/es/es/secretaria-sae">Asuntos Estudiantiles</a></li>
                                <li className="footer-list__item" style={{lineHeight: '0.9em'}}><a target="blank" href="https://utn.edu.ar/es/es/secretaria-sctyp">Ciencia, Tecnología y Posgrado</a></li>
                                <li className="footer-list__item" style={{lineHeight: '0.9em'}}><a target="blank" href="https://utn.edu.ar/es/es/secretaria-cs/cs-home">Consejo Superior</a></li>
                              </ul>
                            </div>
                            <div className="col-md-6 mt-2" style={{fontSize: '90%'}}>
                              <ul>
                                <li className="footer-list__item" style={{lineHeight: '0.9em'}}><a target="blank" href="https://utn.edu.ar/es/secretaria-sceu" data-unsp-sanitized="clean">Cultura y Extensión Universitaria</a></li>
                                <li className="footer-list__item" style={{lineHeight: '0.9em'}}><a target="blank" href="https://utn.edu.ar/es/secretaria-politicas-institucionales/spi-inicio" data-unsp-sanitized="clean">Políticas Institucionales</a></li>
                                <li className="footer-list__item" style={{lineHeight: '0.9em'}}><a target="blank" href="https://utn.edu.ar/es/secretaria-rrii" data-unsp-sanitized="clean">Relaciones Internacionales</a></li>
                                <li className="footer-list__item" style={{lineHeight: '0.9em'}}><a target="blank" href="https://utn.edu.ar/es/secretaria-tic/pagina-tic-inicio">TIC</a></li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div id="enlaces" className="col-md-4">
                          <h6 style={{fontWeight: 400,color:'white'}}>ENLACES UTN</h6>
                          <div className="row">
                            <div className="col-md-12 mt-2" style={{fontSize: '90%'}}>
                              <ul>
                                <li className="footer-list__item" style={{lineHeight: '0.9em'}}><a href="http://www.dasuten.utn.edu.ar/" target="blank">DASUTeN</a></li>
                                <li className="footer-list__item" style={{lineHeight: '0.9em'}}><a href="http://www.edutecne.utn.edu.ar/inicio.html" target="blank">eDUTecNe</a></li>
                                <li className="footer-list__item" style={{lineHeight: '0.9em'}}><a href="http://www.aputn.org.ar/" target="blank">APUTN</a></li>
                                <li className="footer-list__item" style={{lineHeight: '0.9em'}}><a href="https://www.adut.org/" target="blank">ADUT</a></li>
                                <li className="footer-list__item" style={{lineHeight: '0.9em'}}><a href="https://www.fagdut.org.ar/" target="blank">FAGDUT</a></li>
                                <li className="footer-list__item" style={{lineHeight: '0.9em'}}><a href="/es/federacion-universitaria-tecnologica/fut-inicio">FUT</a></li>
                                <li className="footer-list__item" style={{lineHeight: '0.9em'}}><a href="https://sidut.org.ar" target="blank">SIDUT</a></li>
                                <li className="footer-list__item" style={{lineHeight: '0.9em'}}><a href="/es/csi" target="blank">CSI</a></li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div></div>
                </div>
                </div>
                </div>
                </div>

                <div id="sp-bottom3" className="col-lg-3 "><div className="sp-column "><div className="sp-module "><div className="sp-module-content">
                    <div className="custom">
                      <h6 style={{fontWeight: 400,color:'white'}}>ENLACES EXTERNOS</h6>
                      <div className="row">
                        <div id="externos" className="col-md-12 mt-2 ">
                          <ul style={{fontSize: '90%'}}>
                            <li className="footer-list__item" style={{lineHeight: '0.9em'}}><a href="https://www.argentina.gob.ar/educacion" target="_blank" rel="noopener noreferrer">Ministerio de Educación</a></li>
                            <li className="footer-list__item" style={{lineHeight: '0.9em'}}><a href="https://www.argentina.gob.ar/educacion/politicasuniversitarias" target="blank">SPU</a></li>
                            <li className="footer-list__item" style={{lineHeight: '0.9em'}}><a href="http://www.cin.edu.ar/" target="blank">CIN</a></li>
                            <li className="footer-list__item" style={{lineHeight: '0.9em'}}><a href="https://confedi.org.ar/" target="blank">CONFEDI</a></li>
                            <li className="footer-list__item" style={{lineHeight: '0.9em'}}><a href="http://www.coneau.gob.ar/CONEAU/">CONEAU</a></li>
                          </ul>
                        </div>
                      </div>
                      <p>&nbsp;</p></div>
      </div></div></div></div>

              <div id="sp-bottom2" className="col-sm-col-sm-6 col-lg-7 ">
                <div className="sp-column ">
                  <div className="sp-module ">
                    <div className="sp-module-content">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default HomePageFooter;
