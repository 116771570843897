import React from "react";
import { useHistory } from "react-router";
import { Row, Col, Card } from "antd";
import { TeamOutlined, SolutionOutlined, SafetyCertificateOutlined } from '@ant-design/icons';

const ModulesList = () => {
  const history = useHistory();

  const handleWidgetClick = item => {
    console.debug(item.goto);
    history.push(item.goto);
  }

  const modules=[
    { key: 1, icon: <SafetyCertificateOutlined />, 
      title: 'CPA ART', subtitle: 'Sistema de la ART', color: '#009AEA',
      goto: '/art'
    },
    { key: 2, icon: <SolutionOutlined />, 
      title: 'CPA RRHH', subtitle: 'Sistema de Recursos Humanos', color: '#E7A514',
      goto: '/rrhh'
    },
    { key: 3, icon: <TeamOutlined />, 
      title: 'AUTH', subtitle: 'Sistema de administración de usuarios y permisos', color: '#E13B23',
      goto: '/administration'
    },
  ]

  return (
    <div style={ { height: '100%' } }>
      <Row gutter={ [ 16, 16 ] }>
        {
          modules && modules.length > 0
            ? modules.map(module => {
              return (<Col xs={ 24 } sm={ 12 } md={ 12 } lg={ 12 } key={ module.key }>
                <Card hoverable className="widget" style={ { backgroundColor: module.color } }
                  onClick={ () => handleWidgetClick(module) }
                >
                  <h1>{ module.icon} { module.title }</h1>
                  <h4>{ module.subtitle }</h4>
                </Card>
              </Col>);
            })
            : ''
        }
      </Row>
    </div>
  );
};

export default ModulesList;
