import Request from "../../../shared/helpers/request";
import AuthenticationHelper from "../../../shared/helpers/authenticationHelper";
import AuthorizationHelper from "../../../shared/helpers/authorizationHelper";

const LoginApi = {
  Login: (email, password) => {
    return Request
      .post("authentication/authenticate", {
        email: email,
        password: password,
      })
      .then((response) => {
        AuthenticationHelper.storeJwtToken(
          "Bearer " + response.data.loginReturnObject.token
        );
        AuthorizationHelper.storeUserPermissions(
          JSON.parse(response.data.loginReturnObject.permisos[0].permisos)
        );
        AuthenticationHelper.storeUser(email);
      })
      .catch((error) => {
        console.error("fallo");
        throw error;
      });
    }
}

export default LoginApi;