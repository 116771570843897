import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import { PageHeader, Button, Form, Card, Input, InputNumber, message } from "antd";
import { SaveOutlined } from '@ant-design/icons';
import { GLOBAL_FORM_LAYOUT } from "../../../../../shared/config/GlobalConfig";

const FormItem = Form.Item;

const UsersForm = () => {
  const history = useHistory();
  const location = useLocation();
  const [user, setUser] = useState(null);
  const [form] = Form.useForm();

  useEffect(() => {
    if(location.pathname.includes('edit')){
      setUser({ 
        key: '1', 
        id: '1', 
        name: 'Juan Pablo Gonzalez Quinteros', 
        age: 41, 
        address: 'Monteagudo 646 - San Miguel de Tucuman - Tucuman', 
      });
      form.setFieldsValue({
        name: 'Juan Pablo Gonzalez Quinteros',
        age: 41,
        address: 'Monteagudo 646 - San Miguel de Tucuman - Tucuman',
      })
    }
  }, []);

  const onFinish = values => {
    message.success('Usuario guardado');
    history.goBack();
  };

  return (
    <div style={ { backgroundColor: '#fff', height: '100%', padding: 8 } }>
      <PageHeader
        className="site-page-header"
        title={ `${ user && user.id ? 'Editar usuario' : 'Nuevo usuario'}` }
        subTitle="Formulario"
        onBack={ () => history.goBack() }
      />
      <div style={ { padding: 20 } }>
        <Card>
          <Form
            { ...GLOBAL_FORM_LAYOUT }
            form={ form }
            name="formulario"
            onFinish={ onFinish }
            initialValues={{
              name: user ? user.name : '',
              age: user ? user.age : '',
              address: user ? user.address : '',
            }}
            scrollToFirstError
          >
            <FormItem name="name" label="Nombre"
              rules={[{required: true, message: 'Por favor ingrese un valor', whitespace: true }]}
            >
              <Input />
            </FormItem>

            <FormItem name="age" label="Edad"
              rules={[{ required: true, message: 'Por favor ingrese un valor' }]}
            >
              <InputNumber style={ { width: '100%' } } />
            </FormItem>

            <FormItem name="address" label="Dirección">
              <Input />
            </FormItem>

            <FormItem wrapperCol={{ span: 16, offset: 8 }}>
              <Button type="primary" htmlType="submit" icon={ <SaveOutlined /> }>Guardar</Button>
            </FormItem>

          </Form>
        </Card>
      </div>
    </div>
  );
}

export default UsersForm;

