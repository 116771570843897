import React, {useState,useEffect} from "react";
import { Drawer, Form, Button, Col, Row, Input, Select, DatePicker,Upload,message,InputNumber,Cascader } from 'antd';
import request from '../../../../../shared/helpers/request';
const { Dragger } = Upload;
const { Option } = Select;

const LoginForm = (props) => {
  return (
    <>
      <Drawer
        title="Autenticacion"
        width={ 420 }
        onClose={props.onClose}
        visible={props.visible}
        bodyStyle={{ paddingBottom: 80 }}
        footer={
          <div
            style={{
              textAlign: 'right',
            }}
          >
            <Button onClick={props.onClose} style={{ marginRight: 8 }}>
              Cancelar
            </Button>
            <Button form="loginForm"  type="primary" key="submit" htmlType="submit">
              Login
            </Button>
          </div>
        }
      >
        <Form id='loginForm' layout="vertical" hideRequiredMark  onFinish={props.onSubmit}>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="user"
                label="Usuario"
                rules={[{ required: true, message: 'Debe ingresar el Usuario' }]}
              >
                <Input/>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="password"
                label="Password"
                rules={[{ required: true, message: 'Debe ingresar el Password' }]}
              >
                <Input.Password/>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Drawer>
    </>
  );
};

export default LoginForm;
