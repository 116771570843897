import React, { Fragment } from 'react';
import AuthenticationHelper from '../../shared/helpers/authenticationHelper';
import './AppLayout.scss';
import { Layout } from 'antd';
import AppLeftMenu from './components/leftMenu/AppLeftMenu';
import AppHeader from './components/header/AppHeader';
import AppContent from "./components/content/AppContent";
import AppFooter from "./components/footer/AppFooter";
import { BrowserRouter as Router, Redirect, Route, Switch,useLocation  } from "react-router-dom";
import AuthorizationHelper from '../../shared/helpers/authorizationHelper';
import ModulesPage from '../modules/ModulesPage';
import HomePage from '../home/HomePage';
import LoginContainer from '../login/LoginContainer';
function AppLayout( ) {
  return (
    <Router>
      <Layout style={{ minHeight: "100vh" }}>
        <Switch>
          <Route path="/login">
            <LoginContainer />
          </Route>
          <Route path="/home">
            <HomePage />
          </Route>

          <Route
            path="/modules"
            component={AuthorizationHelper.requireAuth(ModulesPage)}
          />

          <Route
            path="/administration"
            render={({ location }) => {
              return AuthenticationHelper.isUserAuthenticated() ? (
                <Fragment>
                  <AppLeftMenu />
                  <Layout className="app-layout">
                    <AppHeader />
                    <AppContent />
                    <AppFooter />
                  </Layout>
                </Fragment>
              ) : (
                <Redirect push to="'login" />
              );
            }}
          />

          <Route path="*">
            <Redirect push to="/home" />
          </Route>
        </Switch>
      </Layout>
    </Router>
  );
}
export default AppLayout;