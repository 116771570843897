import React, { useState } from "react";
import { Modal, Transfer } from "antd";

const PermisosModal = props => {
  const permisos = [
    {key: 0, title: 'Ver Usuarios'}, 
    {key: 1, title: 'Editar Usuarios'}, 
    {key: 2, title: 'Eliminar Usuarios'}
  ];
  
  const [targetKeys, setTargetKeys] = useState(props.user 
    ? permisos.filter(item => props.user.permisos.includes(item.title)).map(item => item.key)
    : []);
  const [selectedKeys, setSelectedKeys] = useState([]);

  const handleChange = (nextTargetKeys, direction, moveKeys) => {
    setTargetKeys(nextTargetKeys);

    console.log('targetKeys: ', nextTargetKeys);
    console.log('direction: ', direction);
    console.log('moveKeys: ', moveKeys);
  };

  const handleSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
    setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);

    console.log('sourceSelectedKeys: ', sourceSelectedKeys);
    console.log('targetSelectedKeys: ', targetSelectedKeys);
  };

  const handleOk = e => {
    props.setVisible(false);
  };

  const handleCancel = e => {
    props.setVisible(false);
  };

  return (
    <Modal
      title="Permisos"
      visible={ props.visible }
      width="80%"
      onOk={ handleOk }
      onCancel={ handleCancel }
    >
      <Transfer
        dataSource={ permisos }
        titles={ ['Permisos disponibles', 'Permisos del usuario'] }
        listStyle={{
          width: '100%',
          height: 300,
        }}
        targetKeys={ targetKeys }
        selectedKeys={ selectedKeys }
        onChange={ handleChange }
        onSelectChange={ handleSelectChange }
        //onScroll={ this.handleScroll }
        render={ item => item.title }
        style={ { marginBottom: 16 } }
      />
    </Modal>
  );
}

export default PermisosModal;

